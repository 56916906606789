<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar <b>Riwayat Bangsal</b> Yang Ditempati
      </div>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <b-table
              striped
              hover
              :items="items"
              :fields="fields"
            >
              <template #cell(no)="data">
                {{data.index+1}}
              </template>

              <template #cell(bedId)="data">
                {{data.item.bed_id}}
              </template>

               <template #cell(patient_id)="data">
                {{data.item.patient_nik}}
              </template>
              
              <template #cell(bed)="data">
                {{data.item.bed_name}}
              </template>
              
              <template #cell(patient)="data">
                {{data.item.patient_name}}
              </template>
              
              <template #cell(allotedTime)="data">
                {{handleDate(data.item.date_in)}}
              </template>
              
              <template #cell(dischargeTime)="data">
                {{handleDate(data.item.date_out)}}
              </template>

              <template #cell(status)="data">
                <div v-if="data.item.is_used == 1">
                  <b-button
                    size="sm"
                    class="mr-1 btn-danger"
                    @click="handleEdit(data.item.id)"
                  >Kosongkan</b-button>
                </div>
                <div v-if="data.item.is_used == 0">
                  <b-button
                    size="sm"
                    class="mr-1 btn-primary"
                    
                  >Selesai</b-button>
                </div>
              </template>
            </b-table>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import Card from '@/view/content/Card.vue'
import module from '@/core/modules/CrudModule.js'
import moment from 'moment'

export default {

  name: 'List',

  components: {
    Card
  },

  data() {
    return {
      
        fields: [
          {
            key: "no",
            label:"No",
            sortable: true,
          },
          {
            key: "bedId",
            label: "Kode Bangsal",
            sortable: true,
          },
          {
            key: "patient_id",
            label: "NIK Pasien",
            sortable: true,
          },
          {
            key: "patient",
            label: "Nama Pasien",
            sortable: true,
          },
          {
            key: "bed",
            label: "Nama Bangsal",
            sortable: true,
          },
          {
            key: "allotedTime",
            label: "Waktu Masuk",
            sortable: true,
          },
          {
            key: "dischargeTime",
            label: "Waktu Keluar",
            sortable: true,
          },
          {
            key: "status",
            label: "Status",
            sortable: true,
          },
        ],
        items: [],

    }
  },

  methods:{
    handleDate(date){
      return moment(date).format('DD-MM-YYYY')
    },
    // async handleEdit(id){
    //   let data = await module.get('used-beds/'+ id)
    //   data['_method'] = 'put'
    //   data['is_used'] = 0

    //   let response = await module.submit(data,'used-beds-delete/'+id);
      
    //   // Check Response
    //   if (response.state != "error") {
    //     // Success
    //     Swal.fire("Berhasil", "Berhasil Mengubah Status", "success");
    //     this.list()
    //   }
    // },
    async list(){
      this.items = await module.list('used-beds-history')
    },
    
    // async handleDelete(id){
    //   let result = await module.delete('used-beds/'+id)
      
    //   if(result){
    //     this.list()
    //   }
    // },


  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Bangsal" },
      { title: "Riwayat Pemakaian" },
    ])
    this.list()
  },

}
</script>

<style>
</style>